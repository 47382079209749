const sentryEnabledFromEnv = process.env.SENTRY_ENABLED

export default async ({ app, router }) => {
  if (!sentryEnabledFromEnv) {
    console.log('Sentry was disabled - skipping setup')
    return
  }

  const Sentry = await import(/* webpackChunkName: "sentry" */ '@sentry/vue')

  Sentry.init({
    app,
    dsn: 'https://24f74065604e4846a9e9681f7d541dae@error-tracking.cloud.wanyplace.de/6759692',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications'
    ],
    release: '__release__',
    environment: '__environment__'
  })

  Sentry.setTag('tenant', '__tenantId__')
  Sentry.setTag('brand', '__brandId__')
  Sentry.setTag('resourceId', '__resourceId__')
  Sentry.setTag('app', 'pmr')
}
